import { LIST as DIFFICULTIES_LIST } from "../../../../../constants/difficulties";

import { CircularProgress, Stack, Typography } from "@mui/material";

import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Radar } from "react-chartjs-2";
import CustomPaper from "../../../../../components/CustomPaper";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { IKnowledgeTrends } from "../../../../../types/analytics";
import { useParams } from "react-router-dom";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const CourseTrendsTab: React.FunctionComponent<{}> = () => {
  const { courseTrainingType, courseId } = useParams<{
    courseTrainingType: string;
    courseId: string;
  }>();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<IKnowledgeTrends>();

  const loadData = useCallback(() => {
    setIsLoading(true);
    axios
      .get<IKnowledgeTrends>("/Alytics/GetKnowledgeTrends", {
        params: {
          courseId,
          courseTrainingType,
        },
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log("error:", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <Stack>
      <Typography variant="h3">Knowledge level in different topics</Typography>
      <CustomPaper sx={{ padding: 2 }}>
        {isLoading ? (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ height: 300 }}
          >
            <CircularProgress />
          </Stack>
        ) : (
          <Radar
            style={{ maxHeight: 600 }}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: "left",
                },
                tooltip: {
                  callbacks: {
                    label(tooltipItem) {
                      return `${tooltipItem.label}: ${tooltipItem.formattedValue}%`;
                    },
                  },
                },
              },
              scales: {
                r: {
                  min: 0,
                  max: 100,
                  beginAtZero: true,
                },
              },
            }}
            data={{
              labels: data?.topics || [],
              datasets: DIFFICULTIES_LIST.map((difficulty, index) => {
                return {
                  label: difficulty.name,
                  data:
                    data?.difficultyTrends.find(
                      (trend) => trend.difficultyTier === difficulty.value
                    )?.trendsData || [],
                  borderColor: `rgb(${difficulty.color[0]}, ${difficulty.color[1]}, ${difficulty.color[2]})`,
                  backgroundColor: `rgba(${difficulty.color[0]}, ${difficulty.color[1]}, ${difficulty.color[2]}, 0.15)`,
                };
              }),
            }}
          />
        )}
      </CustomPaper>
    </Stack>
  );
};

export default CourseTrendsTab;
