import { useEffect, useState } from "react";

import {
  Stack,
  Typography,
  Chip,
  Avatar,
  CircularProgress,
  Grid,
} from "@mui/material";

import {
  COURSE_TRAINING_TYPE_LIST,
  CourseTrainingType,
} from "../constants/courseTrainingType";

import Calendar from "../components/Icons/Calendar";
import Hash from "../components/Icons/Hash";
import Clock from "../components/Icons/Clock";
import Target from "../components/Icons/Target";

import { COURSE_STATUS_LIST } from "../constants/courseStatus";

import moment from "moment";
import { ICourseAnalytics } from "../types/analytics";
import axios from "axios";
import { useAppSelector } from "../hooks/redux";
import CustomPaper from "./CustomPaper";
import ArchDiagram from "./Arch";

interface CourseDetailsProps {
  courseId: number;
  courseTrainingType: CourseTrainingType;
  employeeId?: number;
  teamId?: number;
}

export default function CourseDetails({
  courseId,
  courseTrainingType,
  employeeId,
  teamId,
}: CourseDetailsProps) {
  const languages = useAppSelector((state) => state.classifiers.languages);

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ICourseAnalytics>();

  useEffect(() => {
    setIsLoading(true);
    axios
      .get<ICourseAnalytics>("/CourseDistributions/GetCourseDistribution", {
        params: {
          id: courseId,
          courseTrainingType: courseTrainingType,
        },
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log("error:", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        style={{
          minHeight: 100,
        }}
      >
        <CircularProgress />
      </Stack>
    );
  }

  if (!data) {
    return null;
  }

  return (
    <div>
      <Grid
        container
        spacing={4}
        style={{
          display: "flex",
        }}
      >
        <Grid item xs={6}>
          <Stack>
            <Typography variant="h3">Training details</Typography>
            <CustomPaper sx={{ padding: 2 }}>
              <Stack spacing={1}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Chip
                    label={
                      COURSE_TRAINING_TYPE_LIST[data.courseTrainingType].name
                    }
                    style={{
                      backgroundColor:
                        COURSE_TRAINING_TYPE_LIST[data.courseTrainingType]
                          .color,
                    }}
                  />
                  <Typography>training</Typography>
                </Stack>
                <Stack direction="row">
                  <Chip
                    label={COURSE_STATUS_LIST[data.status].name}
                    style={{
                      backgroundColor: COURSE_STATUS_LIST[data.status].color,
                    }}
                  />
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Calendar />
                  <Typography>
                    {moment(data.startDate).format("L")}
                    {data.endDate && ` - ${moment(data.endDate).format("L")}`}
                  </Typography>
                </Stack>
                {[
                  CourseTrainingType.Regular,
                  CourseTrainingType.Mandatory,
                ].includes(data.courseTrainingType) && (
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography>Receive lessons on</Typography>
                    <Stack direction="row" spacing={1}>
                      {moment.weekdays().map((day: string) => {
                        const isScheduled =
                          // @ts-ignore
                          data[day.toLowerCase()];

                        if (isScheduled) {
                          return (
                            <Avatar
                              key={day}
                              sx={{
                                bgcolor: "text.primary",
                                width: 24,
                                height: 24,
                                fontSize: 12,
                              }}
                            >
                              {day[0]}
                              {["T", "S"].includes(day[0]) ? day[1] : ""}
                            </Avatar>
                          );
                        }
                        return null;
                      })}
                    </Stack>
                  </Stack>
                )}
                {[
                  CourseTrainingType.Regular,
                  CourseTrainingType.Mandatory,
                ].includes(data.courseTrainingType) && (
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Clock />
                    <Typography>{data.lessonTime}</Typography>
                  </Stack>
                )}
                {[
                  CourseTrainingType.Instant,
                  CourseTrainingType.Mandatory,
                ].includes(data.courseTrainingType) && (
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Hash />
                    <Typography>{data.lessonCount} lessons</Typography>
                  </Stack>
                )}
                {[
                  CourseTrainingType.Instant,
                  CourseTrainingType.Mandatory,
                ].includes(data.courseTrainingType) && (
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Target />
                    <Typography>
                      {data.correctAnswersCriteriaCount}/{data.lessonCount}{" "}
                      successfully completed lesson goal
                    </Typography>
                  </Stack>
                )}
                <Stack direction="row" spacing={1} alignItems="center">
                  <Chip label={languages[data.languageId].languageName} />
                  <Typography>language</Typography>
                </Stack>
              </Stack>
            </CustomPaper>
          </Stack>
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            display: "flex",
          }}
        >
          <Stack flex={1}>
            <Typography variant="h3">
              {teamId
                ? "Successfully completed lesson average"
                : "Successfully completed lessons"}
            </Typography>
            <CustomPaper
              sx={{
                position: "relative",
                minHeight: 200,

                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ArchDiagram
                primaryText={
                  data.courseTrainingType === CourseTrainingType.Mandatory
                    ? `77%`
                    : `${data.correctAnswersCriteriaCount}/${data.lessonCount}`
                }
                color={
                  data?.courseTrainingType === CourseTrainingType.Regular
                    ? "default"
                    : false
                    ? // courseDetails?.lessonsCorrect  >= courseDetails?.correctAnswersCriteriaCount
                      "success"
                    : "error"
                }
                secondaryText={
                  data?.courseTrainingType !== CourseTrainingType.Regular
                    ? data?.courseTrainingType === CourseTrainingType.Mandatory
                      ? `Goal: ${data?.correctAnswersCriteria}%`
                      : `Goal: ${data?.correctAnswersCriteriaCount}/${data?.lessonCount}`
                    : undefined
                }
                completion={60}
                // completion={
                //   (courseDetails?.lessonsCorrect /
                //     courseDetails?.lessonsAnswered) *
                //   100
                // }
              />
            </CustomPaper>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
}
