// import { useEffect, useState } from "react";

// import { authentication } from "@microsoft/teams-js";
// import { IAnswerFeedback, IAnswerFeedbackResponse } from "../types/answer";

import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Stack,
  Typography,
} from "@mui/material";
import BookOpen from "./Icons/BookOpen";
import { IUserCourse } from "../types/course";
import moment from "moment";
import { CourseTrainingType } from "../constants/courseTrainingType";

interface CourseCardProps {
  item: IUserCourse;
}

const CourseCard: React.FC<CourseCardProps> = ({ item }) => {
  return (
    <Card
      sx={{
        border: item.active ? "1px solid #1C1917" : undefined,
        borderRadius: 8,
        opacity: item.active ? 1 : 0.6,
        flex: 1,
      }}
      elevation={0}
    >
      <CardActionArea
        sx={{
          paddingX: 4,
          paddingY: 2,
          color: "#1C1917",
          height: "100%",
          "&:hover": {
            backgroundColor: "#1C1917",
            color: "white",
          },
        }}
      >
        <CardMedia>
          <BookOpen width={100} height={100} color="#F47923" />
        </CardMedia>
        <CardContent>
          <Stack>
            <Typography variant="h4" component="h2" color="inherit">
              {item.courseName}
            </Typography>
            <Typography variant="body2" color="inherit">
              in team <b>{item.channelName}</b>
            </Typography>
            {item.endDate &&
              item.courseTrainingType !== CourseTrainingType.Regular && (
                <Typography
                  variant="body2"
                  style={{
                    color: "#F47923",
                  }}
                >
                  Deadline:
                  {moment(item.endDate).format("L")}
                </Typography>
              )}
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default CourseCard;
