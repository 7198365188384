import { useCallback, useEffect, useRef, useState } from "react";

import axios from "axios";

import { Link as RouterLink, useParams } from "react-router-dom";

import {
  Autocomplete,
  Breadcrumbs,
  Chip,
  Grid,
  Link,
  Popover,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  IEmployeeAnalytics,
  IEmployeeAnalyticsCourse,
  IEmployeesAnalyticsFilters,
} from "../../../../types/analytics";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import Helper from "../../../../components/Helper";
import { EmployeesAnalyticsHelper } from "../../../../constants/helpers";
import GridToolbar from "../../../../components/Grid/Toolbar";
import xlsx, { IJsonSheet } from "json-as-xlsx";
import moment from "moment";
import Chevron from "../../../../components/Icons/Chevron";
import {
  COURSE_TRAINING_TYPE_LIST,
  CourseTrainingType,
} from "../../../../constants/courseTrainingType";
import {
  COURSE_STATUS_LIST,
  CourseStatus,
} from "../../../../constants/courseStatus";
import _ from "lodash";

interface IQueryOptions {
  channelId?: number;
  contentId?: number;
  status?: CourseStatus;
  courseTrainingType?: CourseTrainingType;
}

const EmployeeAnalytics: React.FunctionComponent<{}> = () => {
  const { employeeId } = useParams<{ employeeId: string }>();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<IEmployeeAnalytics>();
  const [filterData, setFilterData] = useState<IEmployeesAnalyticsFilters>();

  const gridRef = useRef(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const [queryOptions, setQueryOptions] = useState<IQueryOptions>({});

  const handleXLSXExport = useCallback(() => {
    if (!data) {
      return;
    }

    const exportData: IJsonSheet[] = [
      {
        sheet: "Employees",
        columns: [
          {
            label: "Course",
            value: "courseName",
          },
          {
            label: "Team",
            value: "teamName",
          },
          {
            label: "Status",
            value: "status",
          },
          {
            label: "Training type",
            value: "courseTrainingType",
          },
          {
            label: "Start Date",
            value: "startDate",
          },
          {
            label: "Deadline",
            value: "endDate",
          },
          {
            label: "Sent lessons",
            value: "lessonsSent",
          },
          {
            label: "Completed lessons",
            value: "completedLessons",
          },
          {
            label: "Expected answers",
            value: "correctAnswers",
          },
          {
            label: "Last distribution",
            value: "lastDistribution",
          },
        ],
        // @ts-ignore
        content: data.courseList,
      },
    ];

    xlsx(exportData, {
      fileName: `${data.displayName.replace(" ", "-")}-analytics`,
    });
  }, [data]);

  useEffect(() => {
    axios
      .get<IEmployeesAnalyticsFilters>("/Alytics/GetEmployeeFilter")
      .then((res) => {
        setFilterData(res.data);
      })
      .catch((err) => {
        console.log("error:", err);
      });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get<IEmployeeAnalytics>("/Alytics/GetEmployeeAnalytics", {
        params: {
          userId: employeeId ? parseInt(employeeId) : 0,
          ...queryOptions,
        },
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log("error:", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [queryOptions, employeeId]);

  const columns: GridColDef<IEmployeeAnalyticsCourse>[] = [
    {
      field: "courseName",
      headerName: "Course",
      sortable: false,
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Link
            to={`/analytics/employees/${employeeId}/course/${params.row.courseTrainingType}/${params.row.courseId}`}
            component={RouterLink}
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "teamName",
      headerName: "Team",
      sortable: false,
      minWidth: 100,
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      minWidth: 100,
      renderCell: (params) => {
        return (
          <Chip
            label={COURSE_STATUS_LIST[params.row.status].name}
            style={{
              backgroundColor: COURSE_STATUS_LIST[params.row.status].color,
            }}
          />
        );
      },
    },
    {
      field: "courseTrainingType",
      headerName: "Training type",
      sortable: false,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Chip
            label={
              COURSE_TRAINING_TYPE_LIST[params.row.courseTrainingType].name
            }
            style={{
              backgroundColor:
                COURSE_TRAINING_TYPE_LIST[params.row.courseTrainingType].color,
            }}
          />
        );
      },
    },
    {
      field: "startDate",
      headerName: "Start date",
      sortable: false,
      minWidth: 140,
      renderCell: (params) => {
        if (!params.value) {
          return "-";
        }

        return moment(params.value).format("L");
      },
    },
    {
      field: "endDate",
      headerName: "Deadline",
      sortable: false,
      minWidth: 140,
      renderCell: (params) => {
        if (!params.row.endDate) {
          return "-";
        }

        return moment(params.value).format("L");
      },
    },
    {
      field: "lessonsSent",
      headerName: "Sent lessons",
      sortable: false,

      minWidth: 200,
    },
    {
      field: "completedLessons",
      headerName: "Completed lessons",
      sortable: false,
      minWidth: 240,
      renderCell: (params) => {
        return (
          <Typography>
            {params.value} ({params.row.completedLessonsPercent}%)
          </Typography>
        );
      },
    },
    {
      field: "correctAnswers",
      headerName: "Expected answers",
      sortable: false,
      minWidth: 230,
      renderCell: (params) => {
        return (
          <Typography>
            {params.value} ({params.row.correctAnswersPercent}%)
          </Typography>
        );
      },
    },
    {
      field: "lastDistribution",
      headerName: "Last distribution",
      sortable: false,
      minWidth: 230,
      renderCell: (params) => {
        if (!params.value) {
          return "-";
        }

        return moment(params.value).format("L LT");
      },
    },
  ];

  return (
    <Stack spacing={2}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Stack direction="row" alignItems="center">
            <Typography component="h1" variant="h2">
              Employee analytics
            </Typography>
            <Helper title={<EmployeesAnalyticsHelper />} />
          </Stack>
        </Grid>
      </Grid>

      <Breadcrumbs
        separator={
          <Chevron
            width={20}
            height={20}
            style={{
              transform: "rotate(90deg)",
            }}
          />
        }
      >
        <Link
          component={RouterLink}
          to={"/analytics/employees"}
          underline="none"
          color="inherit"
        >
          Employees
        </Link>
        <Typography variant="inherit">{data?.displayName}</Typography>
      </Breadcrumbs>

      <DataGrid
        ref={gridRef}
        disableColumnFilter
        getRowId={(row) => `${row.courseId}-${row.courseTrainingType}`}
        rows={data?.courseList || []}
        components={{
          Toolbar: () => {
            return (
              <GridToolbar
                filterCount={
                  Object.values(queryOptions).filter((item) => item).length
                }
                toggleFilter={() => setIsFilterOpen(true)}
                onExportXLSX={handleXLSXExport}
              />
            );
          },
        }}
        columns={columns}
        loading={isLoading}
        autoHeight
        disableRowSelectionOnClick
        disableColumnMenu
        pageSizeOptions={[100]}
      />
      <Popover
        open={isFilterOpen}
        anchorEl={gridRef.current}
        onClose={() => setIsFilterOpen(false)}
        style={{
          marginTop: 40,
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Stack spacing={2} sx={{ padding: 2, width: 400, maxWidth: "100%" }}>
          <Typography variant="h6">Filters</Typography>
          <Autocomplete
            options={filterData ? filterData.channelList : []}
            value={
              filterData
                ? filterData.channelList.find(
                    (item) => item.id === queryOptions.channelId
                  )
                : null
            }
            getOptionLabel={(option) => option.teamName}
            renderInput={(params) => <TextField {...params} label="Team" />}
            onChange={(e, value) => {
              setQueryOptions((prev) => ({
                ...prev,
                channelId: value ? value.id : undefined,
              }));
            }}
          />
          <Autocomplete
            options={filterData ? filterData.contentList : []}
            value={
              filterData
                ? filterData.contentList.find(
                    (item) => item.id === queryOptions.contentId
                  )
                : null
            }
            getOptionLabel={(option) => option.contentName}
            renderInput={(params) => <TextField {...params} label="Content" />}
            onChange={(e, value) => {
              setQueryOptions((prev) => ({
                ...prev,
                contentId: value ? value.id : undefined,
              }));
            }}
          />
          <Autocomplete
            options={
              Object.keys(COURSE_STATUS_LIST) as unknown as CourseStatus[]
            }
            getOptionLabel={(option: CourseStatus) =>
              COURSE_STATUS_LIST[option].name
            }
            renderInput={(params) => <TextField {...params} label="Status" />}
            value={queryOptions.status}
            onChange={(e, value) => {
              setQueryOptions((prev) => ({
                ...prev,
                status: value as CourseStatus,
              }));
            }}
          />
          <Autocomplete
            options={
              Object.keys(
                COURSE_TRAINING_TYPE_LIST
              ) as unknown as CourseTrainingType[]
            }
            getOptionLabel={(option: CourseTrainingType) =>
              COURSE_TRAINING_TYPE_LIST[option].name
            }
            renderInput={(params) => (
              <TextField {...params} label="Training type" />
            )}
            value={queryOptions.courseTrainingType}
            onChange={(e, value) => {
              setQueryOptions((prev) => ({
                ...prev,
                courseTrainingType: value as CourseTrainingType,
              }));
            }}
          />
        </Stack>
      </Popover>
    </Stack>
  );
};

export default EmployeeAnalytics;
